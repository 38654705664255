export class MenuItems {
    id: number;
    name: string;
    icon : string;
    routerLink : string;
    active:boolean;
  
    constructor(data: any) {
      if (data) {
        this.id = data.id;
        this.name = data.name;
        this.icon = data.icon;
        this.routerLink = data.routerLink;
        this.active = data.active;
      }
    }
  }
  