import { Component } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { Router, Params } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CustomValidators } from "../core/validator";
import { UserService } from "src/services/user.service";
import { QuizService } from "../../services/quiz.service";
import { FirebaseUserModel } from "src/models/user.model";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent {
  registerForm: FormGroup;
  errorMessage: string = "";
  successMessage: string = "";
  isAgreed = true;
  constructor(
    public authService: AuthService,
    private router: Router,
    public userService: UserService,
    public quizService: QuizService,
    private fb: FormBuilder,
  ) {
    this.createForm();
  }

  createForm() {
    this.registerForm = this.fb.group(
      {
        displayName: ["", Validators.required],
        email: ["", Validators.required],
        password: ["", Validators.required],
        confirmPassword: ["", Validators.required],
      },
      {
        validators: [
          CustomValidators.MatchValidator("password", "confirmPassword"),
        ],
      }
    );
  }

  tryFacebookLogin() {
    this.authService.doFacebookLogin().then(
      (res) => {
        this.router.navigate(["/user"]);
      },
      (err) => console.log(err)
    );
  }

  tryTwitterLogin() {
    this.authService.doTwitterLogin().then(
      (res) => {
        this.router.navigate(["/user"]);
      },
      (err) => console.log(err)
    );
  }

  tryGoogleLogin() {
    this.authService.doGoogleLogin().then(
      (res) => {
        this.router.navigate(["/user"]);
      },
      (err) => console.log(err)
    );
  }

  tryRegister() {
    console.log(this.registerForm.valid);
    if (this.registerForm.valid) {
      this.authService.doRegister(this.registerForm.value).then(
        (res) => {
          this.errorMessage = "";
          this.successMessage = "Your account has been created";
          this.populateCurrenUser(this.registerForm.value);
        },
        (err) => {
          console.log(err);
          this.errorMessage = err.message;
          this.successMessage = "";
        }
      );
    }
  }

  get passwordMatchError() {
    return (
      this.registerForm.getError("mismatch") &&
      this.registerForm.get("confirmPassword")?.touched
    );
  }

  populateCurrenUser(user) {
    this.userService.getCurrentUser().then(
      (res) => {
        let _user = new FirebaseUserModel(res);
        _user.displayName = user.displayName;
        this.quizService.createUser(_user).then((res) => {
          setTimeout(() => {
            this.router.navigate(["/login"]);
          }, 1000);
          this.userService.newUserWelcomeNotificaiton(_user);
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }
  agree(event) {
    this.isAgreed = event.target.checked;
  }
}
