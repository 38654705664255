import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { BreakpointObserver } from "@angular/cdk/layout";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent implements AfterViewInit {
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;
  header: string;
  constructor(private observer: BreakpointObserver) {}

  ngOnInit(): void {
    this.header = "FANSHAWE DRIVING SCHOOL";
  }

  ngAfterViewInit(): void {
    this.toggleSidebar();
  }

  toggleSidebar(): void {
    this.observer.observe(["(max-width: 800px)"]).subscribe((res) => {
      if (res.matches) {
        this.sidenav.mode = "over";
        if (this.sidenav.opened) this.sidenav.close();
      } else {
        this.sidenav.mode = "side";
        if (!this.sidenav.opened) this.sidenav.open();
      }
    });
  }
}
