import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { Router, Params } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CustomValidators } from "../core/validator";
import { UserService } from "src/services/user.service";
import { QuizService } from "../../services/quiz.service";
import { FirebaseUserModel } from "src/models/user.model";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  errorMessage: string = "";
  successMessage: string = "";

  constructor(
    public authService: AuthService,
    private router: Router,
    public userService: UserService,
    public quizService: QuizService,
    private fb: FormBuilder
  ) {
    this.createForm();
  }

  ngOnInit(): void {}

  createForm() {
    this.forgotPasswordForm = this.fb.group({
      email: ["", Validators.required],
    });
  }

  forgotPassword() {
    if (this.forgotPasswordForm.valid) {
      this.authService
        .sendPasswordResetLink(this.forgotPasswordForm.value)
        .then((res) => {
          this.errorMessage = "";
          this.successMessage = "Password reset link has been sent your email address. Kindly verify it and login using your new password!";
          console.log(res);
          setTimeout(() => {
            this.router.navigate(["login"]);
          }, 5000);
        })
        .catch((err) => {
          this.successMessage = "";
          this.errorMessage = "Unable to process your request! Verify your email and try again later..";
          console.log(err);
        });
    }
  }
}
