<div class="navbar">
  <div class="logo">
    <a href="/home"
      ><img
        class="logo-sidebar"
        mat-card-image
        src="https://fanshawedrivingschool.com/wp-content/uploads/2020/10/fanshawedrivingschool.jpg" title="Fanshawe Driving School"  
        alt="Fanshawe Driving School"
    /></a>
  </div>
  <!-- <div class="top">
    <mat-icon>settings</mat-icon>
  </div> -->
  <div class="user" *ngIf="!!user">
    <img *ngIf="!!user.image" src="{{ user.image }}" alt="" />
    <h4 class="name">Welcome, {{ this.user.displayName }}</h4>
    <span>last login : </span>
    <p class="email">
      {{ this.user.lastSignInTime | date : "MMMM d y, h:mm a" }}
    </p>
  </div>
  <div class="menu">
    <div class="menu-head">Shortcuts</div>
    <div class="menu-item">
      <button
        *ngFor="let menu of this.menuItems; let i = index"
        mat-button
        class="menu-button"
        [routerLink]="[menu.routerLink]"
      >
        <mat-icon>{{ menu.icon }}</mat-icon>
        <span>{{ menu.name }}</span>
      </button>
      <button mat-button class="menu-button" (click)="openWebNewTab('https://fanshawedrivingschool.com/locations/')">
        <mat-icon>web</mat-icon>
        <span>Buy Driving Package(s)</span>
      </button>
      <button mat-button class="menu-button" (click)="openWebNewTab('https://fanshawedrivingschool.com/deals-and-promo/')">
        <mat-icon>shop</mat-icon>
        <span>Deals & Promo</span>
      </button>
      <button mat-button class="menu-button" (click)="openDialog()">
        <!-- <button mat-button class="menu-button" (click)="logout()"> -->
        <mat-icon>logout</mat-icon>
        <span>Log Out</span>
      </button>
    </div>
    <div class="footer">
      Developed By :
      <a href="https://www.driveadvisor.ca" target="_blank">Driveadvisor.ca</a>
    </div>
  </div>
</div>
