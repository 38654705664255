import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { FlexLayoutModule } from "@angular/flex-layout";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

// Angular Material Components
import { MaterialModule } from "./material.module";
import { MatSidenavModule } from "@angular/material/sidenav";

// Custom Components
import { NavbarComponent } from "./navbar/navbar.component";
import { LogoutDialog } from "./navbar/navbar.component";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { UserResolver } from "./user/user.resolver";
import { AuthGuard } from "./core/auth.guard";
import { AuthService } from "../services/auth.service";
import { UserService } from "../services/user.service";
import { HomeService } from "../services/home.service";

// Firebase
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { environment } from "../environments/environment";
import { LayoutComponent } from "./layout/layout.component";
import { MatDialogModule } from "@angular/material/dialog";
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    LayoutComponent,
    NavbarComponent,
    LogoutDialog,
    ForgotPasswordComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    MatSidenavModule,
    MatDialogModule
  ],
  providers: [
    AuthService,
    UserService,
    UserResolver,
    AuthGuard,
    HomeService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
