import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/services/auth.service";
import { UserService } from "src/services/user.service";
import { FirebaseUserModel } from "../../models/user.model";
import { BreakpointObserver } from "@angular/cdk/layout";
import { HomeService } from "src/services/home.service";
import { MenuItems } from "src/models/menu-items.model";
import { MatDialog } from "@angular/material/dialog";
import { QuizService } from "src/services/quiz.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  user: FirebaseUserModel;
  menuItems: MenuItems[];
  constructor(
    public userService: UserService,
    public authService: AuthService,
    private homeService: HomeService,
    private dialog: MatDialog,
    private router: Router,
    private quizService: QuizService
  ) {}

  ngOnInit(): void {
    this.populateCurrenUser();
    this.getMenuItems();
  }

  populateCurrenUser() {
    this.userService.getCurrentUser().then(
      (res) => {
        console.log(res);
        this.user = new FirebaseUserModel(res, null);
        this.quizService.getUser(this.user).subscribe((u) => {
          //console.log(u);
          const _user = new FirebaseUserModel(u[0], res.metadata.lastSignInTime);
          this.user  = _user;
          localStorage.setItem("userData", JSON.stringify(this.user));
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  logout() {
    this.authService.doLogout().then(
      (res) => {
        this.router.navigate(["login"]);
      },
      (error) => {
        console.log("Logout error", error);
      }
    );
  }

  getMenuItems() {
    this.homeService.getMenuItems().subscribe(
      (res) => {
        //this.isLoading = false;
        const _menuItems: MenuItems[] = res.map((response) => {
          return new MenuItems(response.payload.doc.data());
        });
        this.menuItems = _menuItems.filter((m) => m.active == true);
        this.menuItems.sort((a, b) => {
          if (a.id < b.id) return -1;
        });
      },
      (err) => {
        //this.isLoading = false;
        console.log(err);
      }
    );
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(LogoutDialog);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.logout();
    });
  }
  // onNoClick(): void {
  //   this.dialog.closeAll();
  // }

  openWebNewTab(url) : void  {
    window.open(url, "_blank");
  }
}

@Component({
  selector: "logout-dialog",
  templateUrl: "../dialogs/logout-dialog.html",
})
export class LogoutDialog {}
