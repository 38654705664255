import { Component, OnDestroy } from "@angular/core";
import { AuthService } from "src/services/auth.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnDestroy {
  title = "Fanshawe Driving School Free Quiz";
  constructor(public authService: AuthService) {}
  ngOnDestroy(): void {
    this.authService.doLogout();
  }
}
