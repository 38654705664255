import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireAuth } from "@angular/fire/auth";
import * as firebase from "firebase/app";
import { QuizService } from "./quiz.service";
import { FirebaseUserModel } from "src/models/user.model";


@Injectable()
export class UserService {
  constructor(public fireStore: AngularFirestore, public afAuth: AngularFireAuth, public quizService: QuizService) {}

  getCurrentUser() {
    return new Promise<any>((resolve, reject) => {
      var user = firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          //console.log(user);
          resolve(user);
        } else {
          reject("No user logged in");
        }
      });
    });
  }

  updateCurrentUser(value) {
    return new Promise<any>((resolve, reject) => {
      var user = firebase.auth().currentUser;
      user
        .updateProfile({
          displayName: value.name,
          photoURL: user.photoURL,
        })
        .then(
          (res) => {
            resolve(res);
          },
          (err) => reject(err)
        );
    });
  }

  newUserRegistrationAdminNotification(user: FirebaseUserModel) {
    return this.fireStore.collection("mail").add({
      to: "driving@fanshawedrivingschool.com",
      //to: "shahdhaval4424@gmail.com",
      message: {
        subject: "New user registration - Quiz Application",
        //subject: subject
        html:
          "<p>Dear Admin,</p>" +
          "<p>New User Registration Received</p>" +
          "<table>" +
          "<tr>" +
          "<td style='width: 10%;'>Name:</td>" +
          "<td style='width: 80%;'>"+ user.displayName +"</td>" +
          "</tr><tr>" +
          "<td style='width: 10%;'>Email:</td>" +
          "<td style='width: 80%;'>"+ user.email +"</td>" +
          "</table>" +
          "<p>Email From <br> - Quiz Application</p>" 
      },
    });
  }

  newUserWelcomeNotificaiton(user: FirebaseUserModel) {
    this.newUserRegistrationAdminNotification(user);
    return this.fireStore.collection("mail").add({
      to: user.email,
      message: {
        subject: "Welcome to G1 Practice Test Web Tool",
        html:
          "<p>Dear "+ (!!user.displayName ? user.displayName : user.email) +",</p>" +
          "<p>Welcome to G1 Practice Test. We are excited to have you as a new user and want to make sure you have the easiest access to our software.</p>" +
          "<p>As a new user, you can now access G1 Practice Test directly from your email inbox using our quick access button. To use this feature, simply click on the button below and you will be taken directly to G1 Practice Test:</p>" +
          "Click here to access G1 Practice Test <a href='https://fanshawedrivingacademy.ca/'>G1 PRACTICE TEST</a>" +
          "<p>Once you are logged in, you can begin using all of the features of G1 Practice Test immediately.</p>"+
          "<p>If you have any questions about using G1 Practice Test, our user guide and support team are always available to assist you. You can find our user guide by clicking on the 'Help' button in the top right-hand corner of the screen. Our support team can be reached by creating support request from left navigation menu</p>"+
          "<p>Thank you for choosing G1 Practice Test as your preferred software. We look forward to working with you!</p>"+
          "<p>Best regards, <br> - Alina, G1 Practice Test Team</p>" 
      },
    });
    
  }
}
