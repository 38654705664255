<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
  <mat-card>
    <mat-card-content>
      <form [formGroup]="registerForm" (ngSubmit)="tryRegister()">
        <img
          mat-card-image
          src="https://fanshawedrivingschool.com/wp-content/uploads/2020/10/fanshawedrivingschool.jpg"
          alt="Fanshawe Driving School"
        />
        <mat-card-title style="margin-bottom: 20px"
          >Create an account</mat-card-title
        >
        <mat-error class="errorMessage" *ngIf="errorMessage">
          {{ errorMessage }}
        </mat-error>
        <mat-label *ngIf="successMessage">
          {{ successMessage }}
        </mat-label>
        <mat-form-field>
          <input
            matInput
            placeholder="Preferred Display Name"
            formControlName="displayName"
            required
          />
          <mat-error> Please provide a preffered display name </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            placeholder="Email"
            formControlName="email"
            required
          />
          <mat-error> Please provide a valid email address </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            type="password"
            placeholder="Password"
            formControlName="password"
            required
          />
          <mat-error> Please provide a valid password </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            type="password"
            placeholder="Confirm password"
            formControlName="confirmPassword"
            required
          />
        </mat-form-field>
        <mat-error *ngIf="passwordMatchError">
          Password does not match
        </mat-error>
        <div style="width: 100%; margin: 0px; text-align: left; padding: 0px">
          <input type="checkbox" style="margin: 5px" checked />
          <span
            >I wish to receive information, offers, recommendations, and updates
            from Fanshawe Driving School!</span
          >
        </div>
        <!-- <div style="clear: both"></div>

        <div style="width: 100%; margin: 10px; text-align: left;">
          <input type="checkbox" style="margin: 5px" /> &nbsp;&nbsp;<span>I want to receive the newsletter</span
          >
        </div> -->

        <!-- <button mat-raised-button color="primary" >Login</button> --><br />
        <button mat-raised-button color="primary" [disabled]="!isAgreed">
          Create Account
        </button>
      </form>
      <mat-label
        >Forgot your password?
        <a href="/forgotpassword">Forgot Password</a></mat-label
      >
    </mat-card-content>
    <footer>
      By signing up, you accept our
      <a
        href="https://fanshawedrivingschool.com/terms-and-conditions/"
        target="_blank"
        >Terms and Conditions.</a
      >
      Please read our
      <a href="https://fanshawedrivingschool.com/school-policy/" target="_blank"
        >Privacy Policy</a
      >
    </footer>
  </mat-card>
</div>
