import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FirebaseUserModel } from "../models/user.model";
import { AngularFirestore } from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { Question } from "../models/question.model";
import { EmailValidator } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class QuizService {
  questionData: Question[] = [];
  seconds: number;
  timer;
  qnProgress: number;
  correctAnsCount: number = 0;

  constructor(
    private http: HttpClient,
    private fireStore: AngularFirestore,
    public router: Router
  ) {}

  getData() {
    return this.fireStore.collection("quizData").snapshotChanges();
  }

  createUser(value: FirebaseUserModel) {
    return this.fireStore.collection("userData").add({ ...value });
  }

  getUser(value: FirebaseUserModel) {
    // let name = this.fireStore
    //   .collection("userData", (ref) => ref.where("email", "==", value.email))
    //   .ref; //.update({ lastLogin: new Date() });
    // console.log(name.path);
    return this.fireStore
      .collection("userData", (ref) => ref.where("email", "==", value.email))
      .valueChanges();
  }

  addData(value) {
    this.fireStore.collection("quizData").add(value);
  }

  sendResult(userId: string, resultData: any) {
    return this.fireStore
      .collection("userData")
      .doc(userId)
      .update({ resultData: resultData });
  }

  signOut() {
    localStorage.clear();
    clearInterval(this.timer);
    this.router.navigate(["/"]);
  }
}
