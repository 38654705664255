import { Component } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { Router, Params } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "page-login",
  templateUrl: "login.component.html",
  styleUrls: ["login.scss"],
})
export class LoginComponent {
  loginForm: FormGroup;
  errorMessage: string = "";

  constructor(
    public authService: AuthService,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.createForm();
  }

  createForm() {
    this.loginForm = this.fb.group({
      email: ["", Validators.required],
      password: ["", Validators.required],
    });
  }

  // tryFacebookLogin() {
  //   this.authService.doFacebookLogin().then((res) => {
  //     this.router.navigate(["/home"]);
  //   });
  // }

  // tryTwitterLogin() {
  //   this.authService.doTwitterLogin().then((res) => {
  //     this.router.navigate(["/user"]);
  //   });
  // }

  // tryGoogleLogin() {
  //   this.authService.doGoogleLogin().then((res) => {
  //     this.router.navigate(["/user"]);
  //   });
  // }

  tryLogin() {
    if (this.loginForm.valid) {
      this.authService.doLogin(this.loginForm.value).then(
        (res) => {
          this.router.navigate(["/home"]);
        },
        (err) => {
          console.log(err);
          this.errorMessage = err.message;
        }
      );
    }
  }
}
