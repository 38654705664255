import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AngularFirestore } from "@angular/fire/firestore";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class HomeService {
  
  constructor(
    private http: HttpClient,
    private fireStore: AngularFirestore,
    public router: Router
  ) {
  }

  getMenuItems() {
    return this.fireStore.collection("menu").snapshotChanges();
  }

  getCategories() {
    return this.fireStore.collection("category").snapshotChanges();
  }
}
