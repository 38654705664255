<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
    <mat-card>
      <mat-card-content>
        <form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPassword()">
          <img
            mat-card-image
            src="https://fanshawedrivingschool.com/wp-content/uploads/2020/10/fanshawedrivingschool.jpg"
            alt="Fanshawe Driving School"
          />
          <mat-card-title>Forgot Password</mat-card-title>
          <mat-error class="errorMessage" *ngIf="errorMessage">
            {{ errorMessage }}
          </mat-error>
          <mat-label *ngIf="successMessage" class="successMessage">
            {{ successMessage }}
          </mat-label>
          <mat-form-field>
            <input
              matInput
              placeholder="Email"
              formControlName="email"
              required
            />
            <mat-error> Please provide a valid email address </mat-error>
          </mat-form-field>
          <button mat-raised-button color="primary">Reset Password</button>
        </form>
        <mat-label>Back to login? <a href="/login">Login</a></mat-label>
      </mat-card-content>
    </mat-card>
  </div>
  