export class FirebaseUserModel {
  image: string;
  displayName: string;
  email : string;
  providerId : string;
  phoneNumber:string;
  lastSignInTime :Date;

  constructor(data: any, lastSignInTime : Date = null) {
    if (data) {
      this.image = !!data.photoURL && data.photoURL || "https://quize-app-76706.web.app/assets/img/no-image.png";
      this.displayName = data.displayName;
      this.email = data.email;
      this.providerId = !!data.providerData && data.providerData.length > 0 ? data.providerData[0].providerId : "";
      this.phoneNumber = data.phoneNumber;
      this.lastSignInTime = !!lastSignInTime ? lastSignInTime : new Date();
    }
  }
}
