<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
  <mat-card>
    <mat-card-content>
      <form [formGroup]="loginForm" class="form" (ngSubmit)="tryLogin()">
        <img
          mat-card-image
          src="https://fanshawedrivingschool.com/wp-content/uploads/2020/10/fanshawedrivingschool.jpg"
          alt="Fanshawe Driving School"
        />
        <mat-card-title>Log In</mat-card-title>
        <mat-error class="errorMessage" *ngIf="errorMessage">
          {{ errorMessage }}
        </mat-error>
        <mat-form-field>
          <input
            matInput
            placeholder="Email"
            formControlName="email"
            required
          />
          <mat-error> Please provide a valid email address </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            type="password"
            placeholder="Password"
            formControlName="password"
            required
          />
          <mat-error> Please provide a valid password </mat-error>
        </mat-form-field>
        <button mat-raised-button color="primary" [disabled]="!loginForm.valid">
          Login
        </button>
      </form>
      <p class="spacer"></p>
      <mat-label class="link"
        >No account yet? <a href="/register">Create an account</a></mat-label
      >
      <p class="spacer"></p>
      <mat-label class="link"
        >Forgot your password? <a href="/forgotpassword">Forgot Password</a></mat-label
      >
    </mat-card-content>
  </mat-card>
</div>
