<mat-sidenav-container class="container">
  <mat-sidenav #sidebar mode="side" opened>
    <app-navbar (click)="toggleSidebar()"></app-navbar>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="content">
      <div class="mat-toolbar">
        <mat-toolbar>
          <mat-icon (click)="sidebar.toggle()">menu</mat-icon>
          <mat-label> {{header}} </mat-label>
        </mat-toolbar>
      </div>
      <div class="main">
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
