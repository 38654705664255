import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import { UserService } from "../../services/user.service";
import { Observable } from "rxjs";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    public afAuth: AngularFireAuth,
    public userService: UserService,
    private router: Router
  ) {}

  canActivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.userService.getCurrentUser().then(
        (user) => {
          if (user) resolve(true);
          else this.router.navigate(['login']);
        },
        (err) => {
          //return resolve(false);
          this.router.navigate(['login']);
        }
      );
    });
  }
}
